import { useEffect } from 'react';

import {
  InvoiceItemsViewData,
  InvoiceItemsViewFields,
  InvoiceItemsViewGroupByOptions
} from '../../InvoiceItemsView.types';
import {
  ProformaInvoiceFormGroupByMode,
  ProformaInvoiceFormViewMode
} from '../../../../../teams/components/forms/ProformaInvoiceForm/ProformaInvoiceForm.types';
import { Currencies } from '../../../../../../types';

import { useReactHookForm } from '../../../../../common/hooks/base/useReactHookForm';
import { usePreviousValue } from '../../../../../../common/hooks/usePreviousValue';
import { isNewValueAndSet } from '../../../../../../utils/isNewValueAndSet';

interface ProformaInvoiceFormFieldsOptions {
  defaultCurrency: Currencies;
  defaultViewMode: ProformaInvoiceFormViewMode;
  defaultGroupBy: ProformaInvoiceFormGroupByMode;
  defaultEnableGrouping: boolean;
  groupByOptions: InvoiceItemsViewGroupByOptions;
}

function useInvoiceItemsViewFields({
  defaultCurrency,
  defaultViewMode,
  defaultGroupBy,
  defaultEnableGrouping,
  groupByOptions
}: ProformaInvoiceFormFieldsOptions) {
  const { control, errors, register, setValue, watch } =
    useReactHookForm<InvoiceItemsViewData>({
      defaultValues: {
        viewMode: defaultViewMode,
        groupBy: defaultGroupBy,
        enableGrouping: defaultEnableGrouping,
        currency: defaultCurrency
      }
    });

  const watchViewMode = watch(InvoiceItemsViewFields.VIEW_MODE);

  const previousViewMode = usePreviousValue(watchViewMode);

  useEffect(() => {
    if (previousViewMode !== undefined && previousViewMode !== watchViewMode) {
      setValue(
        InvoiceItemsViewFields.GROUP_BY,
        groupByOptions[watchViewMode][0].options[0]
          .value as ProformaInvoiceFormGroupByMode
      );
    }
  }, [groupByOptions, previousViewMode, setValue, watchViewMode]);

  const prevCurrency = usePreviousValue(defaultCurrency);
  const prevDefaultViewMode = usePreviousValue(defaultViewMode);
  const prevDefaultGroupBy = usePreviousValue(defaultGroupBy);
  const prevDefaultEnableGrouping = usePreviousValue(defaultEnableGrouping);

  useEffect(() => {
    if (isNewValueAndSet(prevCurrency, defaultCurrency)) {
      setValue(InvoiceItemsViewFields.CURRENCY, defaultCurrency);
    }
    if (
      isNewValueAndSet(prevDefaultViewMode, defaultViewMode) ||
      isNewValueAndSet(prevDefaultGroupBy, defaultGroupBy) ||
      isNewValueAndSet(prevDefaultEnableGrouping, defaultEnableGrouping)
    ) {
      setValue(InvoiceItemsViewFields.VIEW_MODE, defaultViewMode);
      setValue(InvoiceItemsViewFields.GROUP_BY, defaultGroupBy);
      setValue(InvoiceItemsViewFields.ENABLE_GROUPING, defaultEnableGrouping);
    }
  }, [
    defaultCurrency,
    defaultEnableGrouping,
    defaultGroupBy,
    defaultViewMode,
    prevCurrency,
    prevDefaultEnableGrouping,
    prevDefaultGroupBy,
    prevDefaultViewMode,
    setValue
  ]);

  return {
    control,
    errors,
    register,
    setValue,
    watchFields: {
      watchViewMode,
      watchGroupBy: watch(InvoiceItemsViewFields.GROUP_BY),
      watchEnableGrouping: watch(InvoiceItemsViewFields.ENABLE_GROUPING)
    },
    registerFields: {
      registerEnabledGrouping: register(InvoiceItemsViewFields.ENABLE_GROUPING)
    }
  };
}

export default useInvoiceItemsViewFields;
