import { useInvoiceItemsViewColumns } from '../../hooks/useInvoiceItemsViewColumns';
import { useInvoiceItemsViewContext } from '../../hooks/useInvoiceItemsViewContext';
import { useProformaInvoiceFormItemsViewDataTable } from '../../../../../teams/components/forms/ProformaInvoiceForm/components/ProformaInvoiceFormItemsView/hooks/useProformaInvoiceFormItemsViewDataTable';

import { IndexTableThreeStatesCheckbox } from '../../../../../common/components/tables/IndexTableThreeStatesCheckbox';

import { Translate } from '../../../../../../helpers/Translate';
import {
  itemCategoriesKeys,
  itemsKeys,
  projectsKeys,
  tasksKeys,
  words
} from '../../../../../../locales/keys';

function InvoiceItemsViewTableHeader() {
  const { items, checkedHash, handleSetCheckedIds } =
    useInvoiceItemsViewContext();

  const { checkboxState, handleCheckItems } =
    useProformaInvoiceFormItemsViewDataTable({
      items,
      checkedHash,
      onCheck: handleSetCheckedIds
    });

  const groupedId = items.map((item) => item.groupedId).join(',');

  const {
    showItemsCol,
    showTasksCol,
    showProjectsCol,
    showCategoriesCol,
    showOwnerCol,
    showQtyCol,
    showCheckboxCol
  } = useInvoiceItemsViewColumns();

  return (
    <thead>
      <tr className="border-b text-gray-600 dark:text-gray-400 border-gray-200 dark:border-gray-600">
        {showCheckboxCol && (
          <th className="text-xs font-semibold py-2 text-left pl-1 pr-2 w-0">
            <IndexTableThreeStatesCheckbox
              checked={checkboxState}
              className="flex items-center justify-center"
              checkBoxClassName="basic-checkbox"
              itemId={groupedId}
              indeterminate={checkboxState === undefined}
              onCheck={handleCheckItems}
              scope="proforma-invoice-items"
            />
          </th>
        )}

        <th
          className="text-xs font-semibold py-2 text-left pl-1 pr-2 w-full"
          colSpan={showCategoriesCol ? 2 : 1}
        >
          {showItemsCol && <Translate id={itemsKeys.name} />}

          {showTasksCol && <Translate id={tasksKeys.name} />}

          {showProjectsCol && <Translate id={projectsKeys.name} />}

          {showCategoriesCol && (
            <Translate id={itemCategoriesKeys.categoryName} />
          )}
        </th>

        {showOwnerCol && (
          <th className="text-xs font-semibold py-2 text-left pl-1 pr-2 w-0">
            <Translate id={words.owner} />
          </th>
        )}

        {showTasksCol && (
          <th className="text-xs font-semibold py-2 text-left pl-2 pr-2 w-0">
            <Translate id={itemsKeys.taskCreated} />
          </th>
        )}

        {showProjectsCol && (
          <th className="text-xs font-semibold py-2 text-left pl-2 pr-2 w-0">
            <Translate id={projectsKeys.singular} />
          </th>
        )}

        {showItemsCol && (
          <th className="text-xs font-semibold py-2 text-left pl-1 pr-2 w-0">
            <Translate id={words.amount} />
          </th>
        )}

        {showQtyCol && (
          <th className="text-xs font-semibold py-2 text-right pl-1 pr-2 w-0">
            <Translate id={itemsKeys.qty} />
          </th>
        )}

        <th
          colSpan={showQtyCol ? 1 : 2}
          className="text-xs font-semibold py-2 text-right pl-1 pr-2 w-0"
        >
          <Translate id={itemsKeys.subTotal} />
        </th>
      </tr>
    </thead>
  );
}

export default InvoiceItemsViewTableHeader;
