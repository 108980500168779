import React from 'react';
import includes from 'lodash/includes';

import { I18nText } from '../../../../../../types';
import { InvoiceStatuses } from '../../../../invoicesTypes';
import { CustomFieldLocations } from '../../../../../customFields/customFieldsTypes';

import { useInvoiceItemsViewContext } from '../../hooks/useInvoiceItemsViewContext';

import { Translate } from '../../../../../../helpers/Translate';
import { MoneyHelper } from '../../../../../../helpers/MoneyHelper';
import { InvoiceCustomFieldsList } from '../../../../../invoiceCustomFields/helpers/InvoiceCustomFieldsList';
import { PercentHelper } from '../../../../../../helpers/PercentHelper';
import { CheckPermissions } from '../../../../../../helpers/CheckPermissions';

import {
  formsFields,
  invoicesKeys,
  words
} from '../../../../../../locales/keys';
import {
  InvoicesPermissions,
  paidInvoiceStatuses
} from '../../../../invoicesConstants';
import { TeamsPermissions } from '../../../../../teams/teamsConstants';

const tax = 0;

interface InvoiceItemsViewTableFooterProps {
  i18nInvoiceTotal?: I18nText;
  withoutPayments?: boolean;
  withPrepayment?: boolean;
  readonly?: boolean;
}

function InvoiceItemsViewTableFooter({
  i18nInvoiceTotal,
  withoutPayments,
  withPrepayment,
  readonly
}: InvoiceItemsViewTableFooterProps) {
  const {
    invoiceStatus,
    payments,
    prepayment,
    prepaymentAmount,
    amountDue,
    invoiceTotal,
    invoice,
    thirdPersonView,
    self,
    isDraft,
    currentUser,
    customFieldsCorrectStatus,
    cacheKeys
  } = useInvoiceItemsViewContext();

  const baseColSpan = 2 + Number(isDraft);

  return (
    <tbody className="break-before-avoid break-inside-avoid border-t border-gray-200 dark:border-gray-600">
      <tr>
        <td colSpan={baseColSpan} />

        <td className="text-xs text-left w-0 pt-2 pb-0.5 pl-2 pr-2 font-medium whitespace-nowrap">
          <Translate id={invoicesKeys.total} />
        </td>
        <td className="text-xs text-right w-0 pt-2 pb-0.5 pl-2 pr-1 whitespace-nowrap">
          <MoneyHelper
            value={invoiceTotal}
            currency={invoice?.selectedCurrency}
            withPrefixIfUsd
            withSuffix
          />
        </td>
      </tr>

      {!withoutPayments && includes(paidInvoiceStatuses, invoiceStatus) && (
        <tr>
          <td colSpan={baseColSpan} />

          <td className="text-xs text-left w-0 pt-0.5 pb-2 pl-2 pr-2 font-medium whitespace-nowrap">
            <Translate id={words.pastPayments} />
          </td>
          <td className="text-xs text-right w-0 pt-0.5 pb-2 pl-2 pr-1 whitespace-nowrap">
            <MoneyHelper
              value={-payments}
              currency={invoice?.selectedCurrency}
              withPrefixIfUsd
              withSuffix
            />
          </td>
        </tr>
      )}

      {withoutPayments && withPrepayment && (
        <>
          <tr>
            <td colSpan={baseColSpan} />

            <td className="text-xs text-left w-0 pt-0.5 pb-2 pl-2 pr-2 font-medium whitespace-nowrap">
              <Translate id={formsFields.prepayment} />
            </td>
            <td className="text-xs text-right w-0 pt-0.5 pb-2 pl-2 pr-1 whitespace-nowrap">
              <PercentHelper percent={prepayment} />
            </td>
          </tr>

          <tr>
            <td colSpan={baseColSpan} />

            <td className="text-xs text-left w-0 pt-0.5 pb-2 pl-2 pr-2 font-medium whitespace-nowrap">
              <Translate id={invoicesKeys.prepaymentAmount} />
            </td>
            <td className="text-xs text-right w-0 pt-0.5 pb-2 pl-2 pr-1 whitespace-nowrap">
              <MoneyHelper
                value={prepaymentAmount}
                currency={invoice?.selectedCurrency}
                withPrefixIfUsd
                withSuffix
              />
            </td>
          </tr>
        </>
      )}

      <tr>
        <td colSpan={baseColSpan} />

        <td className="text-xs text-left w-0 pt-0.5 pb-2 pl-2 pr-2 font-medium whitespace-nowrap">
          <Translate id={words.tax} />
        </td>
        <td className="text-xs text-right w-0 pt-0.5 pb-2 pl-2 pr-1 whitespace-nowrap">
          <MoneyHelper
            value={tax}
            currency={invoice?.selectedCurrency}
            withPrefixIfUsd
            withSuffix
          />
        </td>
      </tr>

      <tr>
        <td colSpan={baseColSpan} />

        <td
          className="text-xs text-right w-0 pl-2 pr-0 pt-2 pb-2 sm:text-md font-semibold border-t border-gray-200 dark:border-gray-600 whitespace-nowrap"
          colSpan={2}
        >
          <div className="flex items-center justify-between gap-4">
            <div className="whitespace-nowrap sm:text-md font-semibold">
              <Translate id={i18nInvoiceTotal || words.amountDue} />
            </div>
            <div className="py-0.5 px-1 sm:px-2 rounded-md bg-yellow-100 dark:bg-yellow-800">
              <MoneyHelper
                value={
                  invoiceStatus === InvoiceStatuses.PAID && !withoutPayments
                    ? invoiceTotal - payments
                    : amountDue
                }
                currency={invoice?.selectedCurrency}
                withPrefixIfUsd
                withSuffix
              />
            </div>
          </div>
        </td>
      </tr>

      <CheckPermissions
        action={
          thirdPersonView
            ? self
              ? TeamsPermissions.READ_SELF_PAYMENTS_INVOICE_AMOUNT_DUE_CUSTOM_FIELDS
              : TeamsPermissions.READ_OTHER_PAYMENTS_INVOICE_AMOUNT_DUE_CUSTOM_FIELDS
            : InvoicesPermissions.READ_PAYMENTS_INVOICE_AMOUNT_DUE_CUSTOM_FIELDS
        }
      >
        <tr>
          <td colSpan={baseColSpan} />

          <td className="px-2 py-2" colSpan={2}>
            <InvoiceCustomFieldsList
              customFieldsLocation={CustomFieldLocations.AMOUNT_DUE}
              invoiceId={invoice?.id}
              companyId={invoice?.generalLedger?.company?.id}
              invoiceCustomFields={invoice?.invoiceCustomFields}
              withoutLocation
              cacheKeys={cacheKeys}
              canCreate={
                !readonly &&
                customFieldsCorrectStatus &&
                currentUser.hasPermissions(
                  thirdPersonView
                    ? self
                      ? TeamsPermissions.CREATE_SELF_PAYMENTS_INVOICE_AMOUNT_DUE_CUSTOM_FIELDS
                      : TeamsPermissions.CREATE_OTHER_PAYMENTS_INVOICE_AMOUNT_DUE_CUSTOM_FIELDS
                    : InvoicesPermissions.CREATE_PAYMENTS_INVOICE_AMOUNT_DUE_CUSTOM_FIELDS
                )
              }
              canUpdate={
                !readonly &&
                customFieldsCorrectStatus &&
                currentUser.hasPermissions(
                  thirdPersonView
                    ? self
                      ? TeamsPermissions.UPDATE_SELF_PAYMENTS_INVOICE_AMOUNT_DUE_CUSTOM_FIELDS
                      : TeamsPermissions.UPDATE_OTHER_PAYMENTS_INVOICE_AMOUNT_DUE_CUSTOM_FIELDS
                    : InvoicesPermissions.UPDATE_PAYMENTS_INVOICE_AMOUNT_DUE_CUSTOM_FIELDS
                )
              }
              canDelete={
                !readonly &&
                customFieldsCorrectStatus &&
                currentUser.hasPermissions(
                  thirdPersonView
                    ? self
                      ? TeamsPermissions.DELETE_SELF_PAYMENTS_INVOICE_AMOUNT_DUE_CUSTOM_FIELDS
                      : TeamsPermissions.DELETE_OTHER_PAYMENTS_INVOICE_AMOUNT_DUE_CUSTOM_FIELDS
                    : InvoicesPermissions.DELETE_PAYMENTS_INVOICE_AMOUNT_DUE_CUSTOM_FIELDS
                )
              }
            />
          </td>
        </tr>
      </CheckPermissions>
    </tbody>
  );
}

export default InvoiceItemsViewTableFooter;
