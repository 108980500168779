import { IconsEnum } from '../../../../../assets/icons/types';
import { ClassName } from '../../../../../types';
import {
  InvoiceCustomFieldBody,
  InvoiceCustomFieldID,
  InvoiceCustomFieldLabel,
  InvoiceCustomFieldLocation,
  InvoiceCustomFieldOptions,
  UpdateInvoiceCustomFieldCacheKeys
} from '../../../invoiceCustomFieldsTypes';

import { useUpdateInvoiceCustomFieldForm } from '../../forms/UpdateInvoiceCustomFieldForm/hooks/useUpdateInvoiceCustomFieldForm';

import {
  FormModalButton,
  FormModalLeftButtonsRenderProps
} from '../../../../../helpers/buttons/FormModalButton';
import { AlertMessage } from '../../../../../helpers/AlertMessage';

import { customFieldsKeys, words } from '../../../../../locales/keys';
import { DeleteInvoiceCustomFieldModalButton } from '../DeleteInvoiceCustomFieldModalButton';
import { UpdateInvoiceCustomFieldForm } from '../../forms/UpdateInvoiceCustomFieldForm';
import { CompanyID } from '../../../../companies/companiesTypes';

const form = 'update-custom-field-form';

interface UpdateInvoiceCustomFieldModalButtonProps {
  className?: ClassName;
  invoiceCustomFieldId: InvoiceCustomFieldID;
  cacheKeys?: UpdateInvoiceCustomFieldCacheKeys;
  withoutLabel?: boolean;
  withoutLocation?: boolean;
  label: InvoiceCustomFieldLabel;
  body: InvoiceCustomFieldBody;
  location: InvoiceCustomFieldLocation;
  options: InvoiceCustomFieldOptions;
  canDelete?: boolean;
  companyId: CompanyID;
}

function UpdateInvoiceCustomFieldModalButton({
  className,
  invoiceCustomFieldId,
  cacheKeys,
  withoutLabel,
  withoutLocation,
  label,
  body,
  location,
  options,
  canDelete,
  companyId
}: UpdateInvoiceCustomFieldModalButtonProps) {
  const {
    validationErrors,
    registerFields,
    control,
    updateInvoiceCustomFieldReset,
    updateInvoiceCustomFieldLoading,
    updateInvoiceCustomFieldErrorMessage,
    handleInitUpdateInvoiceCustomFieldForm,
    handleUpdateInvoiceCustomField,
    watchOptions,
    watchSaveInCompany
  } = useUpdateInvoiceCustomFieldForm({
    invoiceCustomFieldId,
    cacheKeys,
    withoutLabel,
    label,
    body,
    location,
    options,
    companyId
  });

  return (
    <FormModalButton
      form={form}
      className={
        className ||
        'py-0.5 pl-0.5 pr-0.5 rounded inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base text-gray-500 hover:text-gray-700 dark:hover:text-gray-300 sm:hidden group-hover:block group-focus-within:block'
      }
      icon={IconsEnum.PENCIL}
      iconClassName="h-4 w-4"
      i18nSubmitText={words.save}
      i18nTitle={customFieldsKeys.editField}
      tooltipI18nText={words.edit}
      isLoading={updateInvoiceCustomFieldLoading}
      onOpen={handleInitUpdateInvoiceCustomFieldForm}
      onClose={updateInvoiceCustomFieldReset}
      onSubmit={handleUpdateInvoiceCustomField}
      leftButtonsChildren={
        canDelete &&
        (({ hideModal }: FormModalLeftButtonsRenderProps) => (
          <DeleteInvoiceCustomFieldModalButton
            cacheKeys={cacheKeys}
            invoiceCustomFieldId={invoiceCustomFieldId}
            onSuccess={hideModal}
          />
        ))
      }
    >
      <UpdateInvoiceCustomFieldForm
        form={form}
        control={control}
        isLoading={false}
        registerLabel={registerFields.registerLabel}
        labelValidationError={validationErrors.labelValidationError}
        locationValidationError={validationErrors.locationValidationError}
        withoutLabel={withoutLabel}
        withoutLocation={withoutLocation}
        registerBody={registerFields.registerBody}
        registerMultiline={registerFields.registerMultiline}
        registerSaveInCompany={registerFields.registerSaveInCompany}
        watchOptions={watchOptions}
        watchSaveInCompany={watchSaveInCompany}
      />

      <AlertMessage
        addClassName="m-4"
        message={updateInvoiceCustomFieldErrorMessage}
      />
    </FormModalButton>
  );
}

export default UpdateInvoiceCustomFieldModalButton;
